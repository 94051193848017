<template>
  <div class="error-form">
    <Header :isHeader="true" />
    <Wrapper :ismain="true">
      <div>
        <p class="big-text">
          {{ translateString("notPossibleToConfirme") }}
        </p>
        <p>
          {{ translateString("dataSaved") }}
        </p>
        <p>
          {{ translateString("proceedWithSchedule") }}
        </p>
        <br>
        <br>
        <Button
          :label="translateString('seeResume')"
          class="elm-mobile"
          btnClass="btn-tertiary "
          @action="goToElement('resumoAnchor')"
        />
      </div>
      <div class="resume-details">
        <Card>
          <template>
            <Input
              id="name"
              :label="translateString('name')"
              :placeholder="translateString('insertName')"
              @updateInput="updateInput($event, 'name')"
              :errorMsg="translateString('insertNameError')"
              :value="contactInfo.name"
            />
            <Input
              id="email"
              label="Email"
              :placeholder="translateString('insertEmail')"
              @updateInput="updateInput($event, 'email')"
              :errorMsg="translateString('insertValidEmail')"
              :value="contactInfo.email"
            />
            <Input
              id="phone"
              :label="translateString('phone')"
              :placeholder="translateString('insertPhone')"
              @updateInput="updateInput($event, 'phone')"
              :errorMsg="translateString('insertValidPhone')"
              :value="contactInfo.phone"
            />
            <TextArea
              id="comments"
              :placeholder="translateString('comments')"
              @updateInput="updateInput($event, 'comments')"
              type="comments"
            />
            <Button
              :label="translateString('send')"
              id="erroForm"
              :btnClass="ctaBtn"
              @action="send('erroForm')"
            />
          </template>
        </Card>
        <div id="resumoAnchor">
          <Card
            v-if="
              servicesSelected.length > 0 ||
              Object.keys(mobility).length > 0 ||
              Object.keys(wash).length > 0
            "
          >
            <template v-slot:header>
              <p class="text-highlight">{{ translateString("services") }}</p>
            </template>
            <template>
              <ListItemComplex
                v-for="(service, index) in servicesSelected"
                :key="index"
              >
                <template v-slot:label>
                  <p class="sm-text">{{ service.group.name }}</p>
                  <p
                    v-for="(s, i) in service.services"
                    :key="i"
                    class="sm-text disabled"
                  >
                    {{ s.name }}
                  </p>
                </template>
                <template v-slot:value>
                  <div class="row">
                    <p class="text">
                      {{ formatPrice(service.group.price, 2, "€") }}
                    </p>
                  </div>
                </template>
              </ListItemComplex>
              <ListItemComplex v-if="Object.keys(mobility).length > 0">
                <template v-slot:label>
                  <p class="sm-text">{{ translateString("mobility") }}</p>
                  <p
                    class="sm-text disabled"
                    v-for="(service, index) in mobility"
                    :key="index"
                  >
                    {{ service.name }}
                  </p>
                </template>
                <template v-slot:value>
                  <div class="row">
                    <p class="text">
                      {{ formatPrice(mobilityPriceTotal, 2, "€") }}
                    </p>
                  </div>
                </template>
              </ListItemComplex>
              <ListItemComplex v-if="Object.keys(wash).length > 0">
                <template v-slot:label>
                  <p class="sm-text">{{ translateString("wash") }}</p>
                  <p
                    class="sm-text disabled"
                    v-for="(service, index) in wash"
                    :key="index"
                  >
                    {{ service.name }}
                  </p>
                </template>
                <template v-slot:value>
                  <div class="row">
                    <p class="text">
                      {{ formatPrice(washPriceTotal, 2, "€") }}
                    </p>
                  </div>
                </template>
              </ListItemComplex>
            </template>
            <ListItemComplex>
              <template v-slot:label>
                <p class="text-highlight">Total</p>
              </template>
              <template v-slot:value>
                <p class="text-highlight">
                  {{ formatPrice(servicesTotal, 2, "€") }}
                </p>
              </template>
            </ListItemComplex>
            <p v-if="totalDisclaimer != ''" class="disclaimer">
              {{ totalDisclaimer }}
            </p>
            <p class="xs-text-italic" v-if="maintenanceDisclaimer">
              {{ translateString("maintencanceDisclaimer") }}
            </p>
          </Card>
          <Card v-if="carPlate">
            <template v-slot:header>
              <p class="text-highlight">{{ translateString("vehicle") }}</p>
            </template>
            <template>
              <div class="car-info">
                <img class="car-image" :src="carImage" alt="car info" />
                <p class="title">{{ carPlate }}</p>
                <p class="text">{{ carName }}</p>
              </div>
            </template>
          </Card>
          <Card v-if="dealerSelected.dealerName">
            <template v-slot:header>
              <p class="text-highlight">{{ translateString("dealer") }}</p>
            </template>
            <ListItemComplex>
              <template v-slot:label>
                <p class="sm-text">{{ dealerSelected.dealerName }}</p>
                <p class="sm-text disabled">
                  {{ dealerSelected.dealerAddress }}<br />
                  {{ dealerSelected.dealerPostalCode }}
                </p>
              </template>
            </ListItemComplex>
          </Card>
          <Card v-if="availabilitySelected.hour">
            <template v-slot:header>
              <p class="text-highlight">{{ translateString("dateHour") }}</p>
            </template>
            <ListItemComplex>
              <template v-slot:label>
                <p class="sm-text">
                  {{ date }} às {{ availabilitySelected.hour }}
                </p>
              </template>
            </ListItemComplex>
          </Card>
        </div>
      </div>
    </Wrapper>
    <Modal :msg="callError" :isActive="callError != ''" />
  </div>
</template>

<script>
import { country } from "@/services/country";

import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/pt";

import Button from "@/components/Buttons/Button.vue";
import Card from "@/components/Card/Card.vue";
import Header from "@/components/Header/Header.vue";
import Input from "@/components/Inputs/Input.vue";
import TextArea from "@/components/Inputs/TextArea.vue";
import ListItemComplex from "@/components/List/ListItemComplex.vue";
import Modal from "@/components/Modal/Modal.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";

import { formatPrice, translateString } from "@/functions/functions.js";
import { validateInputs } from "@/functions/validations.js";

export default {
  name: "ErrorForm",
  components: {
    Button,
    Card,
    Header,
    Input,
    ListItemComplex,
    Modal,
    TextArea,
    Wrapper,
  },
  data() {
    return {
      servicesSelected: this.$store.state.servicesSelectedCompletedList,
      servicesTotal: this.$store.state.servicesTotal,
      dealerSelected: this.$store.state.dealerSelected,
      carImage: this.$store.state.carImage
        ? this.$store.state.carImage
        : require("@/assets/car_placeholder.png"),
      carPlate: this.$store.state.carPlate,
      carName: this.$store.state.carName
        ? this.$store.state.carName
        : this.$store.state.carNF.brand + " " + this.$store.state.carNF.model,
      availabilitySelected: this.$store.state.availabilitySelected,
      mobility: this.$store.state.mobilityServiceSelected,
      mobilityPriceTotal: this.$store.state.mobilityServicesTotal,
      wash: this.$store.state.washServiceSelected,
      washPriceTotal: this.$store.state.washServicesTotal,

      ctaBtn: "disabled ",
      contactInfo: this.$store.state.contactInfo,
      cgName: this.$store.state.cgName,
      cgEmail: this.$store.state.cgEmail,
      cgPhone: this.$store.state.cgPhone,
      obs: "",
      formResponse: "",

      date: "",
      tiresDisclaimer: false,
      maintenanceDisclaimer: false,
    };
  },
  computed: {
    finalError() {
      return this.$store.state.finalError;
    },
    errorFormReady() {
      return this.$store.state.errorFormReady;
    },
    totalDisclaimer() {
      let disclaimer = "";

      if (this.$store.state.totalDisclaimer.length > 0) {
        disclaimer += "* ";
        this.$store.state.totalDisclaimer.forEach((el) => {
          disclaimer += el + ", ";
        });

        disclaimer = disclaimer.slice(0, -2);

        disclaimer += " não inclduído no preço";
      }

      return disclaimer;
    },
    callError() {
      return this.$store.state.callError;
    },
  },
  created() {
    if (this.cgName != "") {
      this.contactInfo.name = this.cgName;
    }
    if (this.cgEmail != "") {
      this.contactInfo.email = this.cgEmail;
    }
    if (this.cgPhone != "") {
      this.contactInfo.phone = this.cgPhone;
    }
    this.date = dayjs(this.availabilitySelected.day)
      .locale(country)
      .format("dddd, DD MMMM YYYY");
    this.checkDisclaimers();
  },
  methods: {
    formatPrice,
    translateString,
    checkDisclaimers() {
      //Manutenção
      Object.keys(this.servicesSelected).forEach((key) => {
        this.servicesSelected[key].subServices.forEach((el) => {
          if (el.subWork) {
            this.tiresDisclaimer = true;
          }
          if (el.external) {
            this.maintenanceDisclaimer = true;
          }
        });
      });
    },
    goTo(page) {
      this.$router.push({ name: page });
    },
    goToElement(id) {
      document.getElementById(id).scrollIntoView();
    },
    send(id) {
      if (Object.values(this.contactInfo).some((x) => x !== null && x !== "")) {
        if (validateInputs(["email", "phone"]).length == 0) {
          this.$store.dispatch("selectOption", {
            selectedValue: "contactInfo",
            value: this.contactInfo,
          });
          this.$store.dispatch("selectOption", {
            selectedValue: "obs",
            value: this.obs,
          });
          this.$store.dispatch("makeAppointment");
          this.$store.dispatch("selectOption", {
            selectedValue: "isLoading",
            value: id,
          });
        }
      }
    },
    updateInput(value, field) {
      if (field != "comments") {
        this.contactInfo[field] = value;
      } else {
        this.obs = value;
      }
    },
  },
  watch: {
    contactInfo: {
      handler(contactInfo) {
        if (Object.values(contactInfo).some((x) => x === null || x === "")) {
          this.ctaBtn = "disabled ";
        } else {
          this.ctaBtn = "";
        }
      },
      deep: true,
    },
    errorFormReady() {
      this.$router.push({
        name: "Confirmation",
        params: { error: this.errorFormReady },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $whiteColor;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba($blackColor, 0.15);
  padding: 1rem 1.5rem;
}

.card,
.card-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}

.car-info {
  margin-top: 0;
  text-align: center;
}

.car-image {
  display: block;
  margin: 0 auto;
  max-width: 300px;
  mix-blend-mode: multiply;
  width: 60%;
}

.resume-details {
  align-items: flex-start;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  @include respond-to-min("md") {
    flex-direction: row;
    #resumoAnchor {
      align-items: flex-start;
      display: grid;
      grid-template-areas:
        "services car"
        "services dealers"
        "services schedule";
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-template-rows: auto;
      gap: 2rem;
      justify-content: flex-start;
      margin-top: 0;

      > :nth-child(1) {
        grid-area: services;
      }
      > :nth-child(2) {
        grid-area: car;
      }
      > :nth-child(3) {
        grid-area: dealers;
      }
      > :nth-child(4) {
        grid-area: schedule;
      }

      > * {
        margin: 0;
      }
    }
  }
}
</style>
